import { FC, useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  paramsForExport,
  setDocumentTitle,
  usdFormatter
} from '../../../helpers/helpers';
import {
  exportWithdrawals,
  getDirectorInfo,
  exportWithdrawalHeaders,
  removeWithdrawalForExport,
  exportLicences,
  exportLicencesHeaders,
  removeLicencesForExport,
  removePackagesForExport,
  exportPackages,
  exportPackagesHeaders
} from './slice';
import { Filters } from './filters';
import { CsvExport } from '../../../Components/Custom/CsvExport';

const PAGE_TITLE = 'Director Info';

const INITIAL_PARAMS: {
  fromDate: string;
  toDate: string;
} = {
  fromDate: '',
  toDate: ''
};

const DirectorInfoPage = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    loading,
    data,
    loadingExportWithdrawals,
    loadingExportLicences,
    loadingExportPackages,
    exportWithdrawalsData,
    exportLicencesData,
    exportPackagesData
  } = useAppSelector((state) => state.directorInfo);

  useEffect(() => {
    dispatch(getDirectorInfo(searchParams));
  }, [searchParams]);

  setDocumentTitle(PAGE_TITLE);

  if (loading && !data) return null;

  const balanceInfo: any = {
    title: 'Balance on accounts',
    info: [
      {
        name: 'Club USDT',
        value: data?.shoppingPoints
      },
      {
        name: 'USDT',
        value: data?.rewardPoints
      },

      {
        name: 'Buyback ULX',
        value: data?.buybackUlx
      }
      // {
      //   name: 'My Life Points',
      //   value: data?.myLifePoints
      // }
    ]
  };

  const usdtWithdrawalsInfo: any = {
    title: 'USDT withdrawals',
    info: [
      {
        name: 'USDT withdrawals',
        value: data?.totalWithdrawals
      },
      {
        name: 'Pending Withdrawals',
        value: data?.pendingWithdrawals
      },
      {
        name: 'Paid Withdrawals',
        value: data?.paidWithdrawals
      },
      {
        name: 'Number of Withdrawals',
        value: data?.withdrawalsCount
      }
    ]
  };

  const purchasesInfo: any = {
    title: 'Package purchases',
    info: [
      {
        name: 'Network purchase',
        value: data?.bankTransferPurchases
      },
      {
        name: 'Cash purchase',
        value: data?.rewardPointsPurchases
      },
      {
        name: 'Club purchase',
        value: data?.shoppingPointsPurchases
      },
      {
        name: 'Loyalty Points Purchase',
        value: data?.loyaltyPointsPurchases
      },
      {
        name: 'Mix Purchase',
        value: data?.mixPurchases
      },
      {
        name: 'Total Purchased Without LP',
        value: data?.totalPurchasesWithoutLP
      },
      {
        name: 'Total purchased',
        value: data?.totalPurchases
      },
      {
        name: 'Number of paid orders network',
        value: data?.bankTransferCount
      },
      {
        name: 'Number of paid orders cash',
        value: data?.rewardPointsCount
      },
      {
        name: 'Number of paid orders CLUB',
        value: data?.shoppingPointsCount
      },
      {
        name: 'Number of paid orders LP',
        value: data?.loyaltyPointsCount
      },
      {
        name: 'Number of paid orders MIX',
        value: data?.mixCount
      }

      // {
      //   name: 'My Life Points',
      //   value: data?.myLifePointsPurchases
      // },
      // {
      //   name: 'Number of My Life Points',
      //   value: data?.myLifePointsCount
      // }
    ]
  };

  const licencePurchases: any = {
    title: 'Licence purchases',
    info: [
      {
        name: 'Network purchase',
        value: data?.bankTransferLicencePurchases
      },
      {
        name: 'Cash purchase',
        value: data?.rewardPointsLicencePurchases
      },
      {
        name: 'Club purchase',
        value: data?.shoppingPointsLicencePurchases
      },

      {
        name: 'Mix Purchase',
        value: data?.mixLicencePurchases
      },
      {
        name: 'Total purchased',
        value: data?.totalLicencePurchases
      },
      {
        name: 'Number of paid orders network',
        value: data?.bankTransferLicenceCount
      },
      {
        name: 'Number of paid orders cash',
        value: data?.rewardPointsLicenceCount
      },
      {
        name: 'Number of paid orders CLUB',
        value: data?.shoppingPointsLicenceCount
      },
      {
        name: 'Number of paid orders MIX',
        value: data?.mixLicenceCount
      }
    ]
  };

  const activationVoucherPurchases: any = {
    title: 'Activation voucher purchases',
    info: [
      {
        name: 'Network purchase',
        value: data?.activationVoucherPurchases?.bankTransferPurchases
      },
      {
        name: 'Cash purchase',
        value: data?.activationVoucherPurchases?.rewardPointsPurchases
      },
      {
        name: 'Club purchase',
        value: data?.activationVoucherPurchases?.shoppingPointsPurchases
      },

      {
        name: 'Mix Purchase',
        value: data?.activationVoucherPurchases?.mixPurchases
      },
      {
        name: 'Total purchased',
        value: data?.activationVoucherPurchases?.totaPurchases
      },
      {
        name: 'Number of paid orders network',
        value:
          data?.activationVoucherPurchases?.bankTransferActivationVoucherCount
      },
      {
        name: 'Number of paid orders cash',
        value:
          data?.activationVoucherPurchases?.rewardPointsActivationVoucherCount
      },
      {
        name: 'Number of paid orders CLUB',
        value:
          data?.activationVoucherPurchases?.shoppingPointsActivationVoucherCount
      },
      {
        name: 'Number of paid orders MIX',
        value: data?.activationVoucherPurchases?.mixActivationVoucherCount
      }
    ]
  };

  const eventTicketsPurchases: any = {
    title: 'Event tickets purchases',
    info: [
      {
        name: 'Network purchase',
        value: data?.eventTicketPurchases?.bankTransferPurchases
      },
      {
        name: 'Cash purchase',
        value: data?.eventTicketPurchases?.rewardPointsPurchases
      },
      {
        name: 'Club purchase',
        value: data?.eventTicketPurchases?.shoppingPointsPurchases
      },

      {
        name: 'Mix Purchase',
        value: data?.eventTicketPurchases?.mixPurchases
      },
      {
        name: 'Total purchased',
        value: data?.eventTicketPurchases?.totaPurchases
      },
      {
        name: 'Number of paid orders network',
        value: data?.eventTicketPurchases?.bankTransferActivationVoucherCount
      },
      {
        name: 'Number of paid orders cash',
        value: data?.eventTicketPurchases?.rewardPointsActivationVoucherCount
      },
      {
        name: 'Number of paid orders CLUB',
        value: data?.eventTicketPurchases?.shoppingPointsActivationVoucherCount
      },
      {
        name: 'Number of paid orders MIX',
        value: data?.eventTicketPurchases?.mixActivationVoucherCount
      }
    ]
  };

  const rewardsInfo: any = {
    title: 'Rewards/bonuses',
    info: [
      {
        name: 'Direct bonus',
        value: data?.directBonusesTotal
      },
      {
        name: 'Team bonus',
        value: data?.teamBonusesTotal
      },
      {
        name: 'Matching bonus',
        value: data?.matchingBonusesTotal
      },
      {
        name: 'Global Pool Bonus',
        value: data?.globalPoolBonusesTotal
      },
      {
        name: 'Rank Rewards',
        value: data?.packageRankRewardsTotal
      },
      {
        name: 'Fast start Bonus',
        value: data?.cashbackBonusesTotal
      },
      {
        name: 'Total Rewards',
        value: data?.totalRewards
      },
      {
        name: 'Top Performer Bonus',
        value: data?.topPerformerPoolBonusesTotal
      },

      {
        name: 'Payout Percent',
        value: data?.payoutPercent
      }
    ]
  };

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Director Info' />

        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader
                loading={loading}
                title={PAGE_TITLE}
                initialParams={INITIAL_PARAMS}
                setSearchParams={setSearchParams}
                actions={<></>}
                clearButtonText='All time'
              />
              <Filters
                params={searchParams}
                setParams={setSearchParams}
                noPagination
                hasTime
              />
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Card className='crm-widget'>
              <PageHeader loading={loading} title={balanceInfo.title} />
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {balanceInfo.info.map((item: any, i: number) => (
                    <Tile
                      item={item}
                      key={i}
                      index={i}
                      data={balanceInfo.info}
                    />
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <Card className='crm-widget'>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <PageHeader
                  loading={loading}
                  title={usdtWithdrawalsInfo.title}
                />
                <div style={{ margin: 10 }}>
                  <CsvExport
                    action={() =>
                      dispatch(exportWithdrawals(paramsForExport(searchParams)))
                    }
                    data={exportWithdrawalsData}
                    headers={exportWithdrawalHeaders}
                    disabled={loadingExportWithdrawals}
                    fileName={`USDT-withdrawals`}
                    removeDataAction={removeWithdrawalForExport}
                  />
                </div>
              </div>

              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {usdtWithdrawalsInfo.info.map((item: any, i: number) => (
                    <Tile
                      item={item}
                      key={i}
                      index={i}
                      data={usdtWithdrawalsInfo.info}
                    />
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <Card className='crm-widget'>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <PageHeader loading={loading} title={purchasesInfo.title} />
                <div style={{ margin: 10 }}>
                  <CsvExport
                    action={() =>
                      dispatch(exportPackages(paramsForExport(searchParams)))
                    }
                    data={exportPackagesData}
                    headers={exportPackagesHeaders}
                    disabled={loadingExportPackages}
                    fileName={`packages`}
                    removeDataAction={removePackagesForExport}
                  />
                </div>
              </div>
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {purchasesInfo.info.map((item: any, i: number) => (
                    <Tile
                      item={item}
                      key={i}
                      index={i}
                      data={purchasesInfo.info}
                    />
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <Card className='crm-widget'>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <PageHeader loading={loading} title={licencePurchases.title} />
                <div style={{ margin: 10 }}>
                  <CsvExport
                    action={() =>
                      dispatch(exportLicences(paramsForExport(searchParams)))
                    }
                    data={exportLicencesData}
                    headers={exportLicencesHeaders}
                    disabled={loadingExportLicences}
                    fileName={`licences`}
                    removeDataAction={removeLicencesForExport}
                  />
                </div>
              </div>

              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {licencePurchases.info.map((item: any, i: number) => (
                    <Tile
                      item={item}
                      key={i}
                      index={i}
                      data={purchasesInfo.info}
                    />
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <Card className='crm-widget'>
              <PageHeader
                loading={loading}
                title={activationVoucherPurchases.title}
              />

              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {activationVoucherPurchases.info.map(
                    (item: any, i: number) => (
                      <Tile
                        item={item}
                        key={i}
                        index={i}
                        data={purchasesInfo.info}
                      />
                    )
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <Card className='crm-widget'>
              <PageHeader
                loading={loading}
                title={eventTicketsPurchases.title}
              />

              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {eventTicketsPurchases.info.map((item: any, i: number) => (
                    <Tile
                      item={item}
                      key={i}
                      index={i}
                      data={purchasesInfo.info}
                    />
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <Card className='crm-widget'>
              <PageHeader loading={loading} title={rewardsInfo.title} />
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {rewardsInfo.info.map((item: any, i: number) => (
                    <Tile
                      item={item}
                      key={i}
                      index={i}
                      data={rewardsInfo.info}
                    />
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>

          {/* Stacking Hubs */}
          <Col lg={12}>
            <Card className='crm-widget'>
              <PageHeader loading={loading} title={'Package orders'} />

              <div className='d-flex'>
                <PageHeader title={'Stacking HUBs:'} />
                <PageHeader
                  title={`Total: ${usdFormatter.format(
                    data.packages?.[0].total
                  )}`}
                />
              </div>

              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {data.packages?.map((item: any, i: number) => {
                    if (item?.title === 'Staking HUBs') {
                      return item.prices?.map((price: any, index: number) => {
                        return (
                          <TileHub
                            item={price}
                            key={i}
                            index={index}
                            data={rewardsInfo.info}
                          />
                        );
                      });
                    }
                  })}
                </Row>
              </CardBody>
            </Card>
          </Col>
          {/* Payment Hubs */}
          <Col lg={12}>
            <Card className='crm-widget'>
              <div className='d-flex'>
                <PageHeader title={'Payment HUBs:'} />
                {data.packages?.map((item: any, index: number) => {
                  if (item.title === 'Payment HUBs') {
                    return (
                      <PageHeader
                        key={index}
                        title={`Total: ${usdFormatter.format(item.total)}`}
                      />
                    );
                  }
                })}
              </div>
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {data.packages?.map((item: any, i: number) => {
                    if (item?.title === 'Payment HUBs') {
                      return item.prices?.map((price: any, index: number) => {
                        return (
                          <TileHub
                            item={price}
                            key={i}
                            index={index}
                            data={rewardsInfo.info}
                          />
                        );
                      });
                    }
                  })}
                </Row>
              </CardBody>
            </Card>
          </Col>
          {/* Gaming Hubs */}
          <Col lg={12}>
            <Card className='crm-widget'>
              <div className='d-flex'>
                <PageHeader title={'Gaming HUBs:'} />
                {data.packages?.map((item: any, index: number) => {
                  if (item.title === 'Gaming HUBs') {
                    return (
                      <PageHeader
                        key={index}
                        title={`Total: ${usdFormatter.format(item.total)}`}
                      />
                    );
                  }
                })}
              </div>
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {data.packages?.map((item: any, i: number) => {
                    if (item?.title === 'Gaming HUBs') {
                      return item.prices?.map((price: any, index: number) => {
                        return (
                          <TileHub
                            item={price}
                            key={i}
                            index={index}
                            data={rewardsInfo.info}
                          />
                        );
                      });
                    }
                  })}
                </Row>
              </CardBody>
            </Card>
          </Col>

          {/* Big Whale  Hubs */}
          <Col lg={12}>
            <Card className='crm-widget'>
              <div className='d-flex'>
                <PageHeader title={'Big Whale HUBs:'} />
                {data.packages?.map((item: any, index: number) => {
                  if (item.title === 'Big Whale packages') {
                    return (
                      <PageHeader
                        key={index}
                        title={`Total: ${usdFormatter.format(item.total)}`}
                      />
                    );
                  }
                })}
              </div>
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {data.packages?.map((item: any, i: number) => {
                    if (item?.title === 'Big Whale packages') {
                      return item.prices?.map((price: any, index: number) => {
                        return (
                          <TileHub
                            item={price}
                            key={i}
                            index={index}
                            data={rewardsInfo.info}
                          />
                        );
                      });
                    }
                  })}
                </Row>
              </CardBody>
            </Card>
          </Col>

          {/* Travel Hubs */}
          <Col lg={12}>
            <Card className='crm-widget'>
              <div className='d-flex'>
                <PageHeader title={'Travel HUBs:'} />
                {data.packages?.map((item: any, index: number) => {
                  if (item.title === 'Travelution packages') {
                    return (
                      <PageHeader
                        key={index}
                        title={`Total: ${usdFormatter.format(item.total)}`}
                      />
                    );
                  }
                })}
              </div>
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {data.packages?.map((item: any, i: number) => {
                    if (item?.title === 'Travelution packages') {
                      return item.prices?.map((price: any, index: number) => {
                        return (
                          <TileHub
                            item={price}
                            key={i}
                            index={index}
                            data={rewardsInfo.info}
                          />
                        );
                      });
                    }
                  })}
                </Row>
              </CardBody>
            </Card>
          </Col>

          {/* FlipMe Hubs */}
          <Col lg={12}>
            <Card className='crm-widget'>
              <div className='d-flex'>
                <PageHeader title={'FlipME:'} />
                {data.packages?.map((item: any, index: number) => {
                  if (item.title === 'Flip Me pacakges') {
                    return (
                      <PageHeader
                        key={index}
                        title={`Total: ${usdFormatter.format(item.total)}`}
                      />
                    );
                  }
                })}
              </div>
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {data.packages?.map((item: any, i: number) => {
                    if (item?.title === 'Flip Me pacakges') {
                      return item.prices?.map((price: any, index: number) => {
                        return (
                          <TileHub
                            item={price}
                            key={i}
                            index={index}
                            data={rewardsInfo.info}
                          />
                        );
                      });
                    }
                  })}
                </Row>
              </CardBody>
            </Card>
          </Col>

          {/* Trading Hubs */}
          <Col lg={12}>
            <Card className='crm-widget'>
              <div className='d-flex'>
                <PageHeader title={'Trading HUBs:'} />
                {data.packages?.map((item: any, index: number) => {
                  if (item.title === 'Trading packages') {
                    return (
                      <PageHeader
                        key={index}
                        title={`Total: ${usdFormatter.format(item.total)}`}
                      />
                    );
                  }
                })}
              </div>
              <CardBody className='p-0 m-0'>
                <Row className='row-cols-md-3 row-cols-1'>
                  {data.packages?.map((item: any, i: number) => {
                    if (item?.title === 'Trading packages') {
                      return item.prices?.map((price: any, index: number) => {
                        return (
                          <TileHub
                            item={price}
                            key={i}
                            index={index}
                            data={rewardsInfo.info}
                          />
                        );
                      });
                    }
                  })}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DirectorInfoPage;

const Tile: FC<any> = ({ item, index, data }) => {
  return (
    <Col
      className={index + 1 === data.length ? 'col-lg-3' : 'col-lg-3 border-end'}
      style={{
        opacity:
          item.name.includes('Global Pool Bonus') ||
          item.name.includes('Top Performer Bonus')
            ? 0.6
            : 1
      }}>
      <div className='mt-3 mt-md-0 py-4 px-3'>
        <h5 className='text-muted text-uppercase fs-13'>
          {item.name} <i className={'fs-18 float-end align-middle '}></i>
        </h5>
        <div className='d-flex align-items-center'>
          <div className='flex-shrink-0'>
            <i className={'display-6 text-muted ' + item.icon}></i>
          </div>
          <div className='flex-grow-1 ms-3'>
            <h2 className='mb-0'>
              <span className='counter-value'>
                <CountUp
                  start={0}
                  prefix={
                    item.name.includes('Number of') ||
                    item.name.includes('Payout Percent')
                      ? ''
                      : '$'
                  }
                  suffix={item.name.includes('Payout Percent') ? '%' : ''}
                  // separator={''}
                  end={item.value}
                  decimals={
                    item.name.includes('Number of') ||
                    item.name.includes('Payout Percent')
                      ? 0
                      : 2
                  }
                  duration={2}
                />
              </span>
            </h2>
          </div>
        </div>
      </div>
    </Col>
  );
};

const TileHub: FC<any> = ({ item, index, data }) => {
  return (
    <Col
      className={
        index + 1 === data.length ? 'col-lg-4' : 'col-lg-4 border-end'
      }>
      <div className='mt-3 mt-md-0 py-4 px-3'>
        <h5 className='text-muted text-uppercase fs-13'>
          {item.price} USDT <i className={'fs-18 float-end align-middle '}></i>
        </h5>
        <div className='d-flex align-items-center'>
          <div className='flex-shrink-0'>
            <i className={'display-6 text-muted ' + item.icon}></i>
          </div>
          <div className='flex-grow-1 ms-3'>
            <h2 className='mb-0'>
              <span className='counter-value'>
                <CountUp
                  start={0}
                  prefix={'$'}
                  suffix={''}
                  // separator={''}
                  end={item.price * item.count}
                  decimals={2}
                  duration={2}
                />
                /
                <CountUp start={0} end={item.count} duration={2} />
              </span>
            </h2>
          </div>
        </div>
      </div>
    </Col>
  );
};
