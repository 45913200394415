import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../helpers/api_helper';
import { CalculationStatus, PackageStatus, UserPackages } from './types';
import {
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';

export const getPackageOrders = createAsyncThunk(
  'packageOrders/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/package/user', {
      params
    });

    return response.data.data;
  }
);

export const updatePackageStatus = createAsyncThunk(
  'packageOrders/updatePackageStatus',
  async (
    { id, calculationStatus }: { id: number; calculationStatus: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put('/package/user/status/paid', {
        id,
        calculationStatus
      });
      toast.success('Successfully updated Package Order');
      return response.data.data.userPackage as UserPackages;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const editDateForAutostake = createAsyncThunk(
  'packageOrders/editDateForAutostake',
  async (
    { id, limitDate }: { id: number; limitDate: string | null },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.put(
        '/package/autostake/datelimit/edit',
        {
          userPackageId: id,
          limitDate
        }
      );
      toast.success(response.data.message);
      return response.data.data.userPackage as UserPackages;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const repeatTotalBonus = createAsyncThunk(
  'packageOrders/repeatTotalBonus',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`/bonus/package/${id}/repeat`);
      toast.success(response.data.message);
      return id;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const stornoPackageOrder = createAsyncThunk(
  'packageOrders/stornoPackageOrder',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('package/user/cancel', { id });
      toast.success(response.data.message);
      return id;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const resendPaymentInstructions = createAsyncThunk(
  'packageOrders/resendPaymentInstructions',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put('/package/user/uncancel', {
        id
      });

      toast.success(response.data.message);
      return id;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);
export const exportPackageOrders = createAsyncThunk(
  'packageOrders/exportPackageOrders',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get(`/package/user/export`, {
      params
    });

    const dataForExport = response.data.data.userPackages.map(
      (po: UserPackages) => ({
        orderId: po?.id,
        userId: po?.user?.id,
        user: `${po?.user?.name} ${po?.user?.surname}`,
        packageName: po?.package?.name,
        amount: `${
          po?.package?.price ? usdFormatter?.format(po?.package?.price) : ''
        }`,
        paymentAddress: po?.paymentAddress || '',
        orderDate: po?.dateCreated
          ? `${handleValidDate(po?.dateCreated)}, ${handleValidTime(
              po?.dateCreated
            )}`
          : '',
        paymentDate: po?.datePaid
          ? `${handleValidDate(po?.datePaid)}, ${handleValidTime(po?.datePaid)}`
          : '',
        lockDate: po?.dateLimitForAutoStake
          ? `${handleValidDate(po?.dateLimitForAutoStake)}, ${handleValidTime(
              po?.dateLimitForAutoStake
            )}`
          : '---',
        status: statusForTable(po?.status, po?.calculationStatus)
      })
    );

    return dataForExport;
  }
);

export const exportHeaders = [
  { label: 'Order ID', key: 'orderId' },
  { label: 'User ID', key: 'userId' },
  { label: 'User', key: 'user' },
  { label: 'Package Name', key: 'packageName' },
  { label: 'Amount', key: 'amount' },
  { label: 'Payment Address', key: 'paymentAddress' },
  { label: 'Order Date', key: 'orderDate' },
  { label: 'Payment Date', key: 'paymentDate' },
  { label: 'Lock Date', key: 'lockDate' },
  { label: 'Status', key: 'status' }
];

export const statusForTable = (status: number, calculationStatus: number) => {
  switch (status) {
    case 1:
      return 'PENDING';
    case 2:
      return calculationStatusForTable(calculationStatus);
    case 3:
      return 'CANCELED';
    case 4:
      return 'CANCELED BY USER';

    default:
      break;
  }
};

export const calculationStatusForTable = (status: number) => {
  switch (status) {
    case CalculationStatus.CALCULATE:
      return 'PAID';
    case CalculationStatus.SKIP_WITH_HUB_CREATION:
      return 'PAID WITH HUB NO CALCULATION NO POPV';
    case CalculationStatus.SKIP_WITHOUT_HUB_CREATION:
      return 'PAID NO HUB NO CALCULATION NO POPV';
    case CalculationStatus.SKIP_WITH_HUB_CREATION_ADD_PO_PV:
      return 'PAID WITH HUB NO CALC WITH POPV';
    case CalculationStatus.SKIP_WITHOUT_HUB_CREATION_ADD_PO_PV:
      return 'PAID NO HUB NO CALC WITH POPV';

    case CalculationStatus.SKIP_WITH_HUB_CREATION_ADD_PO:
      return 'PAID WITH HUB NO CALCULATION WITH PO';
    case CalculationStatus.SKIP_WITHOUT_HUB_CREATION_ADD_PO:
      return 'PAID NO HUB NO CALCULATION WITH PO';
    case CalculationStatus.SKIP_WITH_HUB_CREATION_ADD_PV:
      return 'PAID WITH HUB NO CALCULATION WITH PV';
    case CalculationStatus.SKIP_WITHOUT_HUB_CREATION_ADD_PV:
      return 'PAID NO HUB NO CALCULATION WITH PV';
    case CalculationStatus.LP_TRADING_ADD_PO:
      return 'PAID (LP) WITH HUB NO CALCULATION NO POPV';

    default:
      break;
  }
};

export interface PackageRankOrdersState {
  data: UserPackages[];
  loading: boolean;
  totalCount: number;
  dataForExport: UserPackages[];
  loadingExport: boolean;
}

const initialState = {
  data: [],
  totalCount: 0,
  loading: false,
  dataForExport: [],
  loadingExport: false
} as PackageRankOrdersState;

const packageOrdersSlice = createSlice({
  name: 'packageOrders',
  initialState,
  reducers: {
    removePackageOrdersForExport(state) {
      state.dataForExport = [];
    }
    // fill in primary logic here
  },
  extraReducers: (builder) => {
    builder.addCase(getPackageOrders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPackageOrders.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.userPackages as UserPackages[];
      state.totalCount = payload.count;
    });
    builder.addCase(getPackageOrders.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updatePackageStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePackageStatus.fulfilled, (state, { payload }) => {
      state.loading = false;
      const index = state.data.findIndex((p) => p.id === payload.id);
      state.data[index] = payload;
    });
    builder.addCase(updatePackageStatus.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(editDateForAutostake.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(editDateForAutostake.fulfilled, (state, { payload }) => {
      state.loading = false;
      const index = state.data.findIndex((p) => p.id === payload.id);
      state.data[index].dateLimitForAutoStake = payload.dateLimitForAutoStake;
    });
    builder.addCase(editDateForAutostake.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(repeatTotalBonus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(repeatTotalBonus.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(repeatTotalBonus.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(stornoPackageOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(stornoPackageOrder.fulfilled, (state, { payload }) => {
      state.loading = false;
      const index = state.data.findIndex((p) => p.id === payload);
      state.data[index].status = PackageStatus.CANCELED;
    });
    builder.addCase(stornoPackageOrder.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(resendPaymentInstructions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      resendPaymentInstructions.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        const index = state.data.findIndex((p) => p.id === payload);
        state.data[index].status = PackageStatus.PENDING;
      }
    );
    builder.addCase(resendPaymentInstructions.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(exportPackageOrders.pending, (state) => {
      state.loadingExport = true;
    });
    builder.addCase(exportPackageOrders.fulfilled, (state, { payload }) => {
      state.loadingExport = false;
      state.dataForExport = payload;
    });
    builder.addCase(exportPackageOrders.rejected, (state) => {
      state.loadingExport = false;
    });
  }
});

export const { removePackageOrdersForExport } = packageOrdersSlice.actions;

export default packageOrdersSlice.reducer;
