import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../../../app/store';
import { axiosInstance } from '../../../helpers/api_helper';
import { SuperAdminUser, SuperAdminUsers } from './types';

export const getSuperAdmin = createAsyncThunk('superAdmin/fetch', async () => {
  const response = await axiosInstance.get(`/admin/user`);

  return response.data.data;
});

export const getSuperAdminById = createAsyncThunk(
  'superAdmin/getById',
  async (userId: string) => {
    const response = await axiosInstance.get(`/admin/user/${userId}`);
    if (response.data.data.user === null) {
      toast.error('No user with this id');
    }
    return response.data.data;
  }
);

export const updateSuperUserPermissions = createAsyncThunk(
  'superAdmin/update',
  async (userId: number | null, { getState, rejectWithValue }) => {
    try {
      const { superAdmin } = getState() as RootState;
      if (superAdmin && superAdmin.user) {
        const { permissions } = superAdmin.user;

        const newPermissions = {
          users: permissions.users,
          packageOrders: permissions.packageOrders,
          ranking: permissions.ranking,
          rankRewards: permissions.rankRewards,
          withdrawals: permissions.withdrawals,
          withdrawalsViewOnly: permissions.withdrawalsViewOnly,
          director: permissions.director,
          superAdmin: permissions.superAdmin,
          teams: permissions.teams,
          bonuses: permissions.bonuses,
          shop: permissions.shop,
          campaign: permissions.campaign,
          news: permissions.news,
          promotions: permissions.promotions,
          contentBlocks: permissions.contentBlocks,
          events: permissions.events,
          eventTickets: permissions.eventTickets,
          helpDesk: permissions.helpDesk,
          faq: permissions.faq,
          licences: permissions.licences,
          packagePaymentAdmin: permissions.packagePaymentAdmin,
          extraordinaryBonuses: permissions.extraordinaryBonuses,
          techAdmin: permissions.techAdmin
        };

        const response = await axiosInstance.put(`/admin/user/permissions`, {
          userId,
          permissions: newPermissions
        });
        if (response.data.data.permissions) {
          toast.success('Successfully changed permissions');
        }
        if (response.data.data.user === null) {
          toast.error('No user with this id');
        }
        return response.data.data;
      }
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const createSuperUserPermissions = createAsyncThunk(
  'superAdmin/create',
  async (user: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/admin/user`, user);
      if (response.data.data.permissions) {
        toast.success('Successfully added user');
      }
      return response.data.data;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const recalculateUserTeamInfo = createAsyncThunk(
  'superAdmin/recalculateUserTeamInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/mlm/user/team/info/recalculate`
      );
      toast.success(response.data.message);
      return response.data.data;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const recalculateRanks = createAsyncThunk(
  'superAdmin/recalculateRanks',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/mlm/user/team/package-ranks/recalculate`
      );
      toast.success(response.data.message);
      return response.data.data;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export const getWalletsExport = createAsyncThunk(
  'superAdmin/getWalletsExport',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/wallet/address/all`);
      toast.success(response.data.message);
      const newWallets = response.data.data.walletAddresses.map(
        (wallet: any) => {
          return {
            ...wallet,
            isUsed: wallet.isUsed ? 'true' : 'false',
            networkType: wallet.networkType === 1 ? 'BEP-20' : 'TRC-20'
          };
        }
      );
      return newWallets;
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);

export interface SuperAdminState {
  data: SuperAdminUsers[];
  user: SuperAdminUser | null;
  loading: boolean;
  loadingWalletExport: boolean;
  userWalletsExports: any;
}

const initialState = {
  data: [],
  user: null,
  loading: false,
  loadingWalletExport: false,
  userWalletsExports: []
} as SuperAdminState;

const SuperAdminSlice = createSlice({
  name: 'superAdmin',
  initialState,
  reducers: {
    removeUserById(state) {
      state.user = null;
    },
    changePermissions(state, { payload }) {
      if (state.user !== null) {
        state.user.permissions = payload;
      }
    },
    removeUserWalletExportData(state) {
      state.userWalletsExports = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSuperAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSuperAdmin.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.users as SuperAdminUsers[];
    });
    builder.addCase(getSuperAdmin.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getSuperAdminById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSuperAdminById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload.user as SuperAdminUser;
    });
    builder.addCase(getSuperAdminById.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateSuperUserPermissions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSuperUserPermissions.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSuperUserPermissions.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createSuperUserPermissions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      createSuperUserPermissions.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.data = [
          ...state.data,
          {
            id: payload.user.id,
            name: payload.user.name,
            surname: payload.user.surname,
            email: payload.user.surname,
            dateCreated: payload.user.dateCreated
          }
        ];
      }
    );
    builder.addCase(createSuperUserPermissions.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(recalculateUserTeamInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(recalculateUserTeamInfo.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(recalculateUserTeamInfo.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(recalculateRanks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(recalculateRanks.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(recalculateRanks.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getWalletsExport.pending, (state) => {
      state.loadingWalletExport = true;
    });
    builder.addCase(getWalletsExport.fulfilled, (state, { payload }) => {
      state.loadingWalletExport = false;
      state.userWalletsExports = payload;
    });
    builder.addCase(getWalletsExport.rejected, (state) => {
      state.loadingWalletExport = false;
    });
  }
});

export const { removeUserById, changePermissions, removeUserWalletExportData } =
  SuperAdminSlice.actions;
export default SuperAdminSlice.reducer;
