import Flatpickr from 'react-flatpickr';
import { handleDateRangeChange } from '../../helpers/helpers';

export const DateRangeFilter = ({
  searchParams,
  setSearchParams,
  param,
  noPagination,
  hasTime
}: {
  searchParams: URLSearchParams;
  setSearchParams: any;
  param?: string;
  noPagination?: boolean;
  hasTime?: boolean;
}) => {
  const from = searchParams.get(param || 'fromDate') || '';
  const to = searchParams.get(param || 'toDate') || '';

  return (
    <div className='mb-2 d-inline-block col-12'>
      <Flatpickr
        className='form-control'
        id='datepicker-publish-input'
        placeholder='Select a date'
        options={{
          enableTime: hasTime,
          time_24hr: true,
          altInput: true,
          altFormat: hasTime ? 'd M Y, H:i' : 'd M Y',
          mode: 'range',
          dateFormat: hasTime ? 'd M Y, H:i' : 'd M Y',
          maxDate: 'today'
        }}
        onChange={(e) => {
          handleDateRangeChange(e, setSearchParams, noPagination, hasTime);
        }}
        value={[from, to]}
      />
    </div>
  );
};
