import {
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';

export const columns = [
  {
    id: 1,
    Header: 'ID',
    accessor: 'id',
    Cell: (cell: any) => {
      return cell.value;
    }
  },
  {
    id: 2,
    Header: 'User ID',
    accessor: 'userId',
    Cell: (cell: any) => {
      return cell.value;
    }
  },
  {
    id: 3,
    Header: 'Price',
    accessor: 'price',
    Cell: (cell: any) => {
      return usdFormatter.format(cell?.value);
    }
  },

  {
    id: 4,
    Header: 'Date Created',
    accessor: 'dateCreated',
    Cell: (cell: any) => (
      <>
        {cell?.value ? (
          <>
            {handleValidDate(cell?.value)},
            <small className='text-muted'>
              {' '}
              {handleValidTime(cell?.value)}
            </small>
          </>
        ) : (
          '---'
        )}
      </>
    )
  },

  {
    id: 5,
    Header: 'Date Paid',
    accessor: 'datePaid',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  },

  {
    id: 6,
    Header: 'Date Converted',
    accessor: 'dateConverted',
    Cell: (cell: any) => (
      <>
        {handleValidDate(cell.value)},
        <small className='text-muted'> {handleValidTime(cell.value)}</small>
      </>
    )
  },

  {
    id: 7,
    Header: 'Package',
    accessor: 'package',
    Cell: (cell: any) => <>{cell?.value?.name}</>
  },
  {
    id: 8,
    Header: 'Loyality Points',
    accessor: 'loyaltyPoints',
    Cell: (cell: any) => <>{cell?.value} LP</>
  }
];
