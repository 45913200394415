import { FC } from 'react';
import { RenderFilters } from '../../../Components/Custom/RenderFilters';
import { INPUT_TYPE_DATE, INPUT_TYPE_SELECT } from '../../../helpers/helpers';

import countries from '../../../common/countries';

interface FiltersProps {
  params: URLSearchParams;
  setParams: any;
  noPagination?: boolean;
  hasTime?: boolean;
}

export const Filters: FC<FiltersProps> = ({
  params,
  setParams,
  noPagination,
  hasTime
}) => {
  const filterInputs = [
    { param: 'fromDate', placeholder: 'From Date', type: INPUT_TYPE_DATE },
    { param: 'toDate', placeholder: 'To Date', type: INPUT_TYPE_DATE },
    {
      param: 'countryCode',
      type: INPUT_TYPE_SELECT,
      options: countries,
      placeholder: 'Country'
    }
  ];

  return (
    <>
      <RenderFilters
        filterInputs={filterInputs}
        searchParams={params}
        setSearchParams={setParams}
        noPagination={noPagination}
        hasTime={hasTime}
      />
    </>
  );
};
