import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { PageHeader } from '../../../Components/Custom/PageHeader';
import { Pagination } from '../../../Components/Custom/pagination';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setDocumentTitle } from '../../../helpers/helpers';

import { Filters } from './filters';

import { GetUserById } from './getUserById';

const PAGE_TITLE = 'BO License expiry';

const LicensesPage = () => {
  const [userId, setUserId] = useState('');

  const { loading } = useAppSelector((state) => state.licenses);

  setDocumentTitle(PAGE_TITLE);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title={PAGE_TITLE} pageTitle='Dashboard' />
        <Row>
          <Col lg={12}>
            <Card id='orderList'>
              <PageHeader loading={loading} title={PAGE_TITLE} />
              <CardBody className='pt-0'>
                <GetUserById userId={userId} setUserId={setUserId} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LicensesPage;
