import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosInstance } from '../../../helpers/api_helper';

export const updateLicenseToExpired = createAsyncThunk(
  'licenses/updateLicenseToExpired',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/user/mlm/licence/expire/${id}`,
        {
          id
        }
      );
      toast.success(response.data.message);
    } catch (error: any) {
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data.error);
    }
  }
);
