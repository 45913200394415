import { useEffect, useState } from 'react';
import { Button, CardBody, Col, Row } from 'reactstrap';
import { SearchInputFilter } from '../../../Components/Custom/SearchInputFilter';
import { SearchSubmitButton } from '../../../Components/Custom/SearchSubmitButton';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { updateLicenseToExpired } from './slice';

export const GetUserById = ({
  userId,
  setUserId
}: {
  setUserId: any;
  userId: string;
}) => {
  const dispatch = useAppDispatch();

  const handleSearchUser = (e: any) => {
    e.preventDefault();
    dispatch(updateLicenseToExpired(Number(userId)));
  };

  return (
    <>
      <form onSubmit={handleSearchUser}>
        <CardBody className='border border-dashed border-end-0 border-start-0'>
          <Row>
            <Col md={3} sm={4}>
              <SearchInputFilter
                placeholder={'User ID'}
                onChange={({ target }: any) => setUserId(target.value)}
                value={userId}
              />
            </Col>
            <Col md={3} sm={4}>
              <button
                type='submit'
                className='btn btn-outline-danger waves-effect waves-light'>
                Expire
              </button>
            </Col>
          </Row>
        </CardBody>
      </form>
    </>
  );
};
