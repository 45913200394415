import Flatpickr from 'react-flatpickr';
import { handleDateChange } from '../../helpers/helpers';
import moment from 'moment';

export const DateFilter = ({
  searchParams,
  setSearchParams,
  param,
  noPagination,
  hasTime,
  placeholder
}: {
  searchParams: URLSearchParams;
  setSearchParams: any;
  param: string;
  noPagination?: boolean;
  hasTime?: boolean;
  placeholder: string;
}) => {
  const date = searchParams.get(param) || '';
  const dt = moment(date).toDate();
  const today = moment().toDate();

  const max = () => {
    if (param === 'fromDate') {
      if (searchParams.get('toDate')) {
        const date = moment(searchParams.get('toDate')).toDate();
        return date;
      } else return today;
    } else return today;
  };

  const min = () => {
    if (param === 'toDate') {
      return moment(searchParams.get('fromDate')).toDate();
    } else return undefined;
  };

  const minDate = min();
  const maxDate = max();

  return (
    <div className='mb-2 d-flex col-12'>
      <Flatpickr
        className='form-control'
        id='datepicker-publish-input'
        placeholder={placeholder}
        options={{
          enableTime: hasTime,
          time_24hr: true,
          altInput: true,
          altFormat: hasTime ? 'd M Y, H:i' : 'd M Y',
          mode: 'single',
          dateFormat: hasTime ? 'd M Y, H:i' : 'd M Y',
          minDate: minDate,
          maxDate: maxDate,

          defaultHour: 0
        }}
        onChange={(e) => {
          handleDateChange(param, e, setSearchParams, noPagination, hasTime);
        }}
        value={[dt]}
      />
      <button
        className='btn btn-primary'
        onClick={() =>
          setSearchParams((prevParams: URLSearchParams) => {
            prevParams.delete(param);
            return prevParams;
          })
        }>
        <i className='ri-close-line'></i>
      </button>
    </div>
  );
};
