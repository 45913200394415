import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../helpers/api_helper';
import {
  formatUSDT,
  handleValidDate,
  handleValidTime,
  usdFormatter
} from '../../../helpers/helpers';

export const getConversions = createAsyncThunk(
  'getConversions/fetch',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/package/converted', {
      params
    });

    return response.data.data;
  }
);

export const exportHeaders = [
  { label: 'ID', key: 'id' },
  { label: 'User ID', key: 'userId' },
  { label: 'Price', key: 'price' },
  { label: 'Date Paid', key: 'datePaid' },
  { label: 'Date Created', key: 'dateCreated' },
  { label: 'Date Converted', key: 'dateConverted' },
  { label: 'Loyalty Points', key: 'loyaltyPoints' },
  { label: 'package', key: 'package' }
];

export const exportConvertedPackages = createAsyncThunk(
  'getConversions/exportConvertedPackages',
  async (params: URLSearchParams) => {
    const response = await axiosInstance.get('/package/converted/export', {
      params
    });

    const dataForExport = response.data.data.userPackages.map(
      (w: Conversions) => ({
        id: w.id,
        userId: w.userId,
        price: w?.price,
        loyaltyPoints: w.loyaltyPoints,
        dateCreated: w.dateCreated
          ? `${handleValidDate(w.dateCreated)}, ${handleValidTime(
              w.dateCreated
            )}`
          : '',
        datePaid: w.datePaid
          ? `${handleValidDate(w.datePaid)}, ${handleValidTime(w.datePaid)}`
          : '',
        dateConverted: w.dateConverted
          ? `${handleValidDate(w.dateConverted)}, ${handleValidTime(
              w.dateConverted
            )}`
          : '',
        package: w.package.name
      })
    );

    return dataForExport;
  }
);

export interface Package {
  id: number;
  name: string;
}

export interface Conversions {
  id: number;
  price: number;
  userId: number;
  dateCreated: string;
  datePaid: string | null;
  dateConverted: string;
  loyaltyPoints: number;
  package: Package;
}

export interface ConversionsState {
  data: Conversions[];
  dataForExport: Conversions[];
  loading: boolean;
  loadingExport: boolean;
  totalCount: number;
}

const initialState = {
  data: [],
  dataForExport: [],
  totalCount: 0,
  loading: false,
  loadingExport: false
} as ConversionsState;

const conversionsSlice = createSlice({
  name: 'conversions',
  initialState,
  reducers: {
    removeUsersPackagesForExport(state) {
      state.dataForExport = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getConversions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getConversions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.userPackages as Conversions[];
      state.totalCount = payload.count;
    });
    builder.addCase(getConversions.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(exportConvertedPackages.pending, (state) => {
      state.loadingExport = true;
    });
    builder.addCase(exportConvertedPackages.fulfilled, (state, { payload }) => {
      state.loadingExport = false;
      state.dataForExport = payload;
    });
    builder.addCase(exportConvertedPackages.rejected, (state) => {
      state.loadingExport = false;
    });
  }
});

export const { removeUsersPackagesForExport } = conversionsSlice.actions;

export default conversionsSlice.reducer;
