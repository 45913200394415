import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserFromLS } from '../helpers/localstorage-helper';

const Navdata = () => {
  // const history = useNavigate();
  //state data
  // const [isDashboard, setIsDashboard] = useState(false);
  // const [isBonuses, setIsBonuses] = useState(false);

  // const [iscurrentState, setIscurrentState] = useState('Dashboard');

  // function updateIconSidebar(e) {
  //   if (e && e.target && e.target.getAttribute('subitems')) {
  //     const ul = document.getElementById('two-column-menu');
  //     const iconItems = ul.querySelectorAll('.nav-icon.active');
  //     let activeIconItems = [...iconItems];
  //     activeIconItems.forEach((item) => {
  //       item.classList.remove('active');
  //       var id = item.getAttribute('subitems');
  //       if (document.getElementById(id))
  //         document.getElementById(id).classList.remove('show');
  //     });
  //   }
  // }

  // useEffect(() => {
  //   document.body.classList.remove('twocolumn-panel');
  //   if (iscurrentState !== 'Dashboard') {
  //     setIsDashboard(false);
  //   }
  //   if (iscurrentState !== 'Bonuses') {
  //     setIsBonuses(false);
  //   }
  // }, [history, iscurrentState, isDashboard, isBonuses]);

  const userFromLS = getUserFromLS();

  const permissions = userFromLS?.permissions;

  const menuItems = [
    {
      label: 'Dashboards',
      isHeader: true,
      permission: true
    },
    {
      id: 'taskList',
      label: 'Task List',
      link: '/task-list',
      permission: true,
      icon: 'ri-file-list-fill'
    },
    {
      id: 'director-info',
      label: 'Director Info',
      link: '/director-info',
      permission: permissions?.director,
      icon: 'ri-folder-info-fill'
    },
    {
      id: 'balance-change',
      label: 'Balance Change',
      link: '/balance-change',
      permission: permissions?.extraordinaryBonuses,
      icon: 'ri-file-fill'
    },
    {
      id: 'super-admin',
      label: 'Super Admin',
      link: '/super-admin',
      permission: permissions?.superAdmin,
      icon: 'ri-admin-fill'
    },
    {
      id: 'users',
      label: 'Users',
      link: '/users',
      permission: permissions?.users,
      icon: 'ri-folder-user-fill'
    },
    {
      id: 'package-orders',
      label: 'Package Orders',
      link: '/package-orders',
      permission: permissions?.packageOrders,
      icon: 'ri-archive-fill'
    },
    {
      id: 'airdrops',
      label: 'Airdrops',
      link: '/airdrops',
      permission: true,
      icon: 'ri-file-list-fill'
    },

    {
      id: 'package-rank-history',
      label: 'Package Rank History',
      link: '/package-rank-history',
      permission: permissions?.packagePaymentAdmin,
      icon: 'ri-file-history-line'
    },
    {
      id: 'package-rank-rewards',
      label: 'Package Rank Reward',
      link: '/package-rank-rewards',
      permission: permissions?.rankRewards,
      icon: 'ri-sort-asc'
    },
    {
      id: 'event-tickets',
      label: 'Event Tickets',
      link: '/event-tickets',
      permission: permissions?.rankRewards,
      icon: 'ri-ticket-line'
    },
    {
      id: 'withdrawals',
      label: 'Withdrawals',
      link: '/withdrawals',
      permission: permissions?.withdrawals || permissions?.withdrawalsViewOnly,
      icon: 'ri-money-dollar-circle-fill'
    },
    {
      id: 'conversions',
      label: 'Conversions',
      link: '/conversions',
      permission: true,
      icon: 'ri-file-paper-2-fill'
    },
    {
      id: 'blacklisted',
      label: 'Blacklisted Wallets',
      link: '/blacklisted',
      permission: true,
      icon: 'ri-file-forbid-fill'
    },
    {
      id: 'blacklistedIDs',
      label: "Blacklisted Users ID's",
      link: '/blacklisted-users',
      permission: true,
      icon: 'ri-file-forbid-fill'
    },
    {
      id: 'performanseIDs',
      label: "Performance ID's",
      link: '/performance',
      permission: true,
      icon: 'ri-file-forbid-fill'
    },
    {
      id: 'teams',
      label: 'Teams',
      link: '/teams',
      permission: permissions?.teams,
      icon: 'ri-team-fill'
    },
    {
      id: 'willyCoins',
      label: 'Free Willy Coins',
      link: '/willy-coins',
      permission: permissions?.teams,
      icon: 'ri-team-fill'
    },
    {
      id: 'content-blocks',
      label: 'Content Blocks',
      link: '/content-blocks',
      permission: permissions?.contentBlocks,
      icon: 'ri-file-text-fill'
    },
    {
      id: 'licenses',
      label: 'Licenses',
      link: '/licenses',
      permission: permissions?.licences,
      icon: 'ri-file-paper-2-fill'
    },
    {
      id: 'techadmin',
      label: 'Tech Admin',
      link: '/tech-admin',
      permission: permissions?.techAdmin,
      icon: 'ri-file-paper-2-fill'
    },
    {
      id: 'faq',
      label: 'Faq',
      link: '/faq',
      permission: permissions?.faq,
      icon: 'ri-question-fill'
    },
    {
      label: 'Bonuses',
      isHeader: true,
      permission: true
    },
    {
      id: 'team-bonus',
      label: 'Team bonus',
      link: '/team-bonus',
      permission: true,
      icon: 'ri-coins-fill'
    },
    {
      id: 'trading-team-bonus',
      label: 'Trading Team bonus',
      link: '/trading-team-bonus',
      permission: true,
      icon: 'ri-coins-fill'
    },
    {
      id: 'direct-bonus',
      label: 'Direct bonus',
      link: '/direct-bonus',
      permission: true,
      icon: 'ri-coins-fill'
    },
    {
      id: 'matching-bonus',
      label: 'Matching bonus',
      link: '/matching-bonus',
      permission: true,
      icon: 'ri-coins-fill'
    },
    {
      id: 'total-bonus',
      label: 'Total bonus',
      link: '/total-bonus',
      permission: true,
      icon: 'ri-coins-fill'
    },
    {
      id: 'customer-bonus',
      label: 'Customer bonus',
      link: '/customer-bonus',
      permission: true,
      icon: 'ri-coins-fill'
    },
    {
      id: 'fast-start-bonus',
      label: 'Fast Start bonus',
      link: '/fast-start-bonus',
      permission: true,
      icon: 'ri-coins-fill'
    },
    {
      id: 'global-pool-bonus',
      label: 'Global Pool bonus',
      link: '/global-pool-bonus',
      permission: true,
      icon: 'ri-coins-fill'
    },
    {
      id: 'top-performer-bonus',
      label: 'Top Performer bonus',
      link: '/top-performer-bonus',
      permission: true,
      icon: 'ri-coins-fill'
    }
    // {
    //   id: 'dashboard',
    //   label: 'Dashboards',
    //   icon: 'ri-bar-chart-2-fill',
    //   link: '/#',
    //   stateVariables: isDashboard,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsDashboard(!isDashboard);
    //     setIscurrentState('Dashboard');
    //     updateIconSidebar(e);
    //   },
    //   permission: true,
    //   subItems: [
    //     {
    //       id: 'director-info',
    //       label: 'Director Info',
    //       link: '/director-info',
    //       parentId: 'dashboard',
    //       permission: permissions?.director
    //     },
    //     {
    //       id: 'balance-change',
    //       label: 'Balance Change',
    //       link: '/balance-change',
    //       parentId: 'dashboard',
    //       permission: permissions?.extraordinaryBonuses
    //     },
    //     {
    //       id: 'super-admin',
    //       label: 'Super Admin',
    //       link: '/super-admin',
    //       parentId: 'dashboard',
    //       permission: permissions?.superAdmin
    //     },
    //     {
    //       id: 'users',
    //       label: 'Users',
    //       link: '/users',
    //       parentId: 'dashboard',
    //       permission: permissions?.users
    //     },
    //     {
    //       id: 'package-orders',
    //       label: 'Package Orders',
    //       link: '/package-orders',
    //       parentId: 'dashboard',
    //       permission: permissions?.packageOrders
    //     },
    //     {
    //       id: 'package-rank-history',
    //       label: 'Package Rank History',
    //       link: '/package-rank-history',
    //       parentId: 'dashboard',
    //       permission: permissions?.packagePaymentAdmin
    //     },
    //     {
    //       id: 'package-rank-rewards',
    //       label: 'Package Rank Reward',
    //       link: '/package-rank-rewards',
    //       parentId: 'dashboard',
    //       permission: permissions?.rankRewards
    //     },
    //     {
    //       id: 'withdrawals',
    //       label: 'Withdrawals',
    //       link: '/withdrawals',
    //       parentId: 'dashboard',
    //       permission: permissions?.withdrawals
    //     },
    //     {
    //       id: 'blacklisted',
    //       label: 'Blacklisted Wallets',
    //       link: '/blacklisted',
    //       parentId: 'dashboard',
    //       permission: true
    //     },
    //     {
    //       id: 'teams',
    //       label: 'Teams',
    //       link: '/teams',
    //       parentId: 'dashboard',
    //       permission: permissions?.teams
    //     },
    //     {
    //       id: 'content-blocks',
    //       label: 'Content Blocks',
    //       link: '/content-blocks',
    //       parentId: 'dashboard',
    //       permission: permissions?.contentBlocks
    //     },
    //     {
    //       id: 'licenses',
    //       label: 'Licenses',
    //       link: '/licenses',
    //       parentId: 'dashboard',
    //       permission: permissions?.licences
    //     },
    //     {
    //       id: 'faq',
    //       label: 'Faq',
    //       link: '/faq',
    //       parentId: 'dashboard',
    //       permission: permissions?.faq
    //     }
    //   ]
    // },

    // {
    //   id: 'bonuses',
    //   label: 'Bonuses',
    //   icon: 'ri-coins-fill',
    //   link: '/bonuses',
    //   stateVariables: isBonuses,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsBonuses(!isBonuses);
    //     setIscurrentState('Bonuses');
    //     updateIconSidebar(e);
    //   },
    //   permission: permissions?.bonuses,

    //   subItems: [
    //     {
    //       id: 'team-bonus',
    //       label: 'Team bonus',
    //       link: '/team-bonus',
    //       parentId: 'bonuses',
    //       permission: true
    //     },
    //     {
    //       id: 'direct-bonus',
    //       label: 'Direct bonus',
    //       link: '/direct-bonus',
    //       parentId: 'bonuses',
    //       permission: true
    //     },
    //     {
    //       id: 'matching-bonus',
    //       label: 'Matching bonus',
    //       link: '/matching-bonus',
    //       parentId: 'bonuses',
    //       permission: true
    //     },
    //     {
    //       id: 'total-bonus',
    //       label: 'Total bonus',
    //       link: '/total-bonus',
    //       parentId: 'bonuses',
    //       permission: true
    //     },
    //     {
    //       id: 'customer-bonus',
    //       label: 'Customer bonus',
    //       link: '/customer-bonus',
    //       parentId: 'bonuses',
    //       permission: true
    //     },
    //     {
    //       id: 'fast-start-bonus',
    //       label: 'Fast Start bonus',
    //       link: '/fast-start-bonus',
    //       parentId: 'bonuses',
    //       permission: true
    //     },
    //     {
    //       id: 'global-pool-bonus',
    //       label: 'Global Pool bonus',
    //       link: '/global-pool-bonus',
    //       parentId: 'bonuses',
    //       permission: true
    //     },
    //     {
    //       id: 'top-performer-bonus',
    //       label: 'Top Performer bonus',
    //       link: '/top-performer-bonus',
    //       parentId: 'bonuses',
    //       permission: true
    //     }
    //   ]
    // }
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
